.BTDProgress {
    background: #f0f0f0; 
    position: relative;
}

.BTDProgress__bar {
    animation: wavy 1s infinite linear;
    background: linear-gradient(90deg, #1976d2 25%, #f0f0f0 50%, #1976d2 75%);
    background-size: 200% 100%;
    height: 4px;
}

.BTDProgress-circular {
    background-color: transparent; 
    height: 40px;
    position: relative;
    width: 40px; 
}

.BTDProgress__spin {
    border: 4px solid #f3f3f3; 
    border-top: 4px solid #1976d2; 
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

@keyframes wavy {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}