/* CalendarListItem CSS */

.CalendarListItem {
    background-color: #F8F9FB;
    border: 1px solid #E6E9EF;
    cursor: pointer;
    height: 100%;
    padding: 20px;
    transition: all 0.3s;
}

.CalendarListItem:hover {
    opacity: 0.7;
}

.CalendarListItem__description {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
}
.CalendarListItem__name {
    font-size: 18px;
}

@media only screen and (max-width: 650px) {
    
    .CalendarListItem {
        margin-bottom: 20px;
    }
}

