/* BookingListItem CSS */

.BookingListItem {
    align-items: center;
    background-color: #fff;
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: grid;
    grid-template-columns: 15% 25% 30% 15% 15%;
    padding: 5px 15px;
}

.BookingListItem:hover {
    background-color: #f8f9fd;
}

.BookingListItem__label {
    display: none;
}

@media only screen and (max-width: 700px) {

    .BookingListItem {
        display: block;
        text-align: center;
    }

    .BookingListItem__date {
        display: flex; 
        justify-content: space-between;
    }
    
    .BookingListItem__label {
        display: inline;
    }
}