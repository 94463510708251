/* BTDTextField */

.BTDTextField {
    font-size: inherit;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    z-index: 1;
}

.AnzaForm {
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #E6E9EF;
    border-radius: 0.25rem;
    display: grid;
    grid-template-columns: 140px auto;
    margin-bottom: 20px;
    position: relative;
}

.BTDTextField.medium {
    width: calc(50% - 10px);
}

.BTDTextField.small {
    width: calc(25% - 20px);
}

.BTDTextField__label {
    background-color: #fff;
    bottom: 5px;
    color: rgba(0,0,0,0.5);
    font-size: inherit;
    position: absolute;
    left: 20px;
    top: 5px;
    transition: all 0.3s;
    z-index: -1;
}

.AnzaForm__label {
    padding: 10px;
    width: 180px;
}

.BTDTextField__label__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    transition: all 0.3s;
}

.BTDTextField__label.active {
    bottom: auto;
    font-size: 11px;
    height: auto;
    left: 15px;
    padding: 0 5px;
    top: -5px;
    z-index: 9;
}

.BTDTextField__label.startIcon {
    left: 45px;
}

.BTDTextField__startIcon {
    background-repeat: no-repeat;
    background-size: contain;
    height: 28px;
    left: 10px;
    opacity: 0.4;
    position: absolute;
    top: 16px;
    width: 35px;
}

.BTDTextField__startIcon-search {
    background-image: url('./magnifying-glass.svg');
}

.BTDTextField__startIcon-clear {
    background-image: url('./close.svg');
    cursor: pointer;
}

.BTDTextField__input {
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    font-family: inherit;
    font-size: inherit;
    display: flex;
    outline: none;
    padding: 18px 20px;
    width: 100%;
}

.AnzaForm__input {
    background-color: #FFFFFF;
    border: none;
    border-left: 1px solid #E6E9EF;
    border-radius: 0.25rem;
    columns: auto;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    padding: 10px;
    margin: 5px;
    max-width: 100%;
    width: 99%;
}

.BTDTextField__input.startIcon {
    padding: 18px 20px 18px 45px;
}

.BTDTextField__error {
    color: red;
    transform: translateY(-20px);
}
