/* SettingsListItem CSS */

.SettingsListItem.card {
    align-items: center;
    display: grid;
    grid-template-columns: 30% auto 30px;
    max-width: 100%;
}

.SettingsListItem__item {
    padding-right: 20px;
}

.SettingsListItem__item__name {
    word-wrap: break-word;
}

@media only screen and (max-width: 700px) {

    .SettingsListItem.card {
        display: block;
        text-align: center;
    }

    .SettingsListItem__item__name {
        margin-bottom: 10px;
    }

    .SettingsListItem__icon {
        display: block;
        margin: 5px 0 0 auto;
        width: max-content;
    }
}
