/* Thank You CSS */

.ThankYouScreen {
    padding: 10vh 0 30vh 0;
}

.ThankYouScreen__message {
    line-height: 1.5;
    margin-bottom: 50px;
    text-align: center;
}