* {
	padding: 0;
	margin: 0;
	list-style-type: none;
	box-sizing: border-box;
	line-height: 1;
}

body {
	background-color: #F8F9FB;
	color: #172E5B;
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

img {
	height: auto;
	width: 100%;
}

/* text */

a {
	text-decoration: none;
	transition: all 0.35s;
}

h1 {
	font-size: 28px;
	font-weight: 500;
}

h2 {
	font-size: 22px;
	font-weight: 400;
	margin-bottom: 20px;
}

h3 {
	font-size: 21px;
	font-weight: 500;
	line-height: 1.5;
}

h4 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 20px;
}

p {
	font-size: 16px;
	line-height: 1.5;
}

label {
	color: #253551;
	font-size: 15px;
	font-weight: 500;
}

.light {
	font-weight: 400;
}

.medium {
	font-weight: 500;
}

.semi-bold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.opacity {
	opacity: 65%;
}

.centre {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}


.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.inline {
	display: inline;
}

.flex {
	display: flex;
}

/* Colours */

.black {
	color: #000;
}

.blue {
	color: #253551;
}

.green {
	color: #6DA726;
}

.grey {
    color: #808080;
}

.light-blue {
	color: #0454A8;
}

.light-grey {
	background-color: #F6F8FB;
}

.orange {
	color: #FE5100;
}

.pink {
	color: #FF38F4;
}

.red {
	color: #BC3B3B;
}

.white {
	color: #fff;
}

.yellow {
	color: #D87F2D;
}

/* Forms */

.form__title {
	margin-bottom: 40px;
}

.form__flexContainer {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.form__grid-2 {
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 10px));
	column-gap: 20px;
}

.form__grid-3 {
	column-gap: 30px;
	display: grid;
	grid-template-columns: repeat(3, calc(33.3333% - 20px));
	row-gap: 30px;
	margin-bottom: 30px;
	max-width: 600px;
}

.form__label {
	display: flex;
	margin-bottom: 8px;
}

.form__link {
	color: #1976d2;
	cursor: pointer;
	margin-bottom: 20px;
}

.form__text {
	margin: 10px 0;
}

.form__tabs__tab {
	cursor: pointer;
	display: inline-block;
	margin-right: 30px;
	padding-bottom: 10px;
	width: max-content;
}

.form__tabs__tab.active {
	border-bottom: 1px solid #FE5100;
}

.form__slider {
	margin: 40px 0;
}

::-webkit-input-placeholder { /* Edge */
	font-family: 'Poppins', sans-serif !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	font-family: 'Poppins', sans-serif !important;
}

::placeholder {
	font-family: 'Poppins', sans-serif !important;
}

.form__gallery {
	display: flex;
	margin-top: 60px;
}

.form__gallery__item {
	align-items: center;
	background-color: #F3F5F7;
	border: 1px solid #E0E5EA;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 165px;
	justify-content: end;
	margin: 0 40px 40px 0;
	max-width: 165px;
	padding-bottom: 37px;
	transition: all 0.25s;
	width: 100%;
}

.form__gallery__item:hover {
	opacity: 0.7;
}

.form__gallery__item__icon {
	background-size: cover;
}

.form__gallery__item__text {
	color: #2A3042;
	font-size: 15px;
	font-weight: 400;
	margin-top: 20px;
}

/* anza form */

.form__customerDetails {
    display: flex;
    margin-bottom: 80px;
}

.form__section {
    display: inline-block;
    width: 50%;
}

.form__section-left {
    border-right: 2px solid #E6E9EF;
    padding-right: 30px;
}

.form__section-right {
    padding-left: 30px;
}

.form__field {
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #E6E9EF;
    border-radius: 0.25rem;
    display: flex;
    margin-bottom: 20px;
}

.form__label {
    padding: 10px;
    width: 180px;
}

.form__label__star {
    color: red;
}

.form__textarea, 
.form__input {
    background-color: #FFFFFF;
    border: none;
    border-left: 1px solid #E6E9EF;
    columns: auto;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    padding: 10px;
    margin: 5px;
    max-width: 100%;
    width: 100%;
}

.form__textarea:focus, 
.form__textarea:active, 
.form__input:focus, 
.form__input:active {
    border: none;
    border-left: 1px solid #E6E9EF;
    outline: none;
}



.searchForm {
    margin-bottom: 40px;
}

.form__input-search {
    border: 1px solid #E6E9EF;
    max-width: 400px;
    outline: 0
}

.form__input-search:focus, .form__input-search:active {
    border: 1px solid #E6E9EF;
    outline: 0;
}

.form__select {
    appearance: none;
    /* background: url("../svg/expand-down.svg") 80% / 26px no-repeat #fff; */
    border: none;
    border-left: 1px solid #E6E9EF;
    border-radius: 0.25rem;
    color: #172E5B;
    cursor: pointer;
    display: block;
    font-family: inherit;
    font-size: inherit;
    height: min-content;
    width: 100%;
    outline: none;
	padding: 15px 10px;
}

.form__::-ms-expand {
    display: none;
}

.form__table__row input.rate {
    background-color: #FCFFD1;
    border: none;
    outline: 0;
}

.totalDiv {
    font-size: 17px;
    margin: 20px 0 0 10px;
}

.totalDiv__item {
    display: grid;
    grid-template-columns: 120px auto;
    margin-bottom: 8px;
}

#totalDiv {
    display: inline-block;
    font-weight: 700;
}

.form__textarea-comments {
    border: 0;
    margin-bottom: 40px;
    max-width: 1000px;
}

.form__submit {
    background-color: #0C3580;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-size: 16px;
    margin: 40px 0;
    padding: 20px;
    width: min-content;
}

.form__submit:hover {
    opacity: 0.75;
}

.form__hidden {
    display: none;
}

::-webkit-input-placeholder { /* Edge */
    color: #172E5B;
    font-family: 'Rubik', sans-serif !important;
    opacity: 0.6;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #172E5B;
    font-family: 'Rubik', sans-serif !important;
    opacity: 0.6;
}

::placeholder {
    color: #172E5B;
    font-family: 'Rubik', sans-serif !important;
    opacity: 0.6;
}

.form__tabs {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
}

.form__tabs__tab {
    background-color: #EAECEF;
    cursor: pointer;
    font-size: 20px;
    margin-right: 2px;
    max-width: max-content;
    padding: 20px 30px 22px 30px;
    text-align: center;
    transform: translateY(2px);
    z-index: 0;
}

.form__tabs__tab:last-child {
    margin-right: none;
}

.form__tabs__tab.active {
    background-color: #f8f9fb;
    border-bottom: none;
    border-left: 2px solid #B8C3D8;
    border-right: 2px solid #B8C3D8;
    border-top: 2px solid #B8C3D8;
    z-index: 9;
}

.tableContainer {
    background-color: #f8f9fb;
    border: 2px solid #B8C3D8;
    margin-bottom: 40px;
    padding: 40px;
    position: relative;
    z-index: 1;
}

.form__table {
    background-color: #ffffff;
    border-top: 1px solid #E6E9EF;
    border-left: 1px solid #E6E9EF;
    /* display: none; */
}

.form__table.active {
    display: block;
}

.form__table-website, 
.form__table-onlineArticles, 
.form__table-edm {
    max-width: 1000px;
}

.form__table__row {
    border-bottom: 1px solid #E6E9EF;
    display: grid;
}

.form__table-magazine .form__table__row {
    grid-template-columns: 17.5% 9% repeat(7, 10.5%);
}

.form__table-website .form__table__row {
    grid-template-columns: 40% 14% 30% 16%;
}

.form__table-edm .form__table__row {
    grid-template-columns: 40% 16% 30% 14%;
}

.form__table-onlineArticles .form__table__row {
    grid-template-columns: 40% 14% 30% 16%;
}

.form__table__row-total {
    border-top: 1px solid #E6E9EF;
}

.form__table__row__th-issue, 
.form__table__row__th-centre, 
.form__table__row__td-centre {
    text-align: center;
}

.form__table__row__td, 
.form__table__row__th {
    border-right: 1px solid #E6E9EF;
    line-height: 1.3;
    padding: 15px;
    position: relative;
}

.form__table__row__td__input {
    font-size: 17px;
}

.form__table__row__th {
    border-bottom: 1px solid #E6E9EF;
}

.form__table__row__td {
    display: inline-block;
    height: 100%;
    width: 100%;
}

.form__table__row__td-issue {
    display: none;
}

.form__table__row__td__checkbox {
    cursor: pointer;
    display: block;
    margin: 0 auto;
}

.form__table__row__td-mobile, 
.form__table__row__td-mobileDateLabel,
.form__table__row__td-mobileQuantityLabel {
    display: none;
}
.form__table__row__td-desktopDateLabel,
.form__table__row__td-desktopQuantityLabel {
    display: block;
}

.infoIcon {
    cursor: pointer;
    float: right;
}

.infoIcon__icon {
    background-color: #172E5B;
    border-radius: 50%;
    color: #ffffff;
    display: block;
    font-family: 'serif';
    font-size: 16px;
    font-weight: bold;
    padding: 1px 0;
    text-align: center;
    width: 22px;
}

.infoBox {
    background-color: #ffffff;
    border: 1px solid #E6E9EF;
    color: #172E5B;
    display: none;
    font-weight: 400;
    padding: 10px;
    position: absolute;
    right: 22px;
    top: -10px;
    width: max-content;
}

.infoBox.active {
    display: block;
}

.form__input-table, 
.form__input-table:hover, 
.form__input-table:focus, 
.form__input-table:active {
    border: none;
    margin: 0;
    padding: 0;
}

.form__scrollingText {
    background-color: #ffffff;
    height: 250px;
    margin-bottom: 40px;
    max-width: 1000px;
    overflow-x: scroll;
    padding: 0 10px 15px 10px;
}

.form__scrollingText__subheading {
    font-size: 18px;
    font-weight: 400;
    padding: 15px 0 5px 0;
}

.form__scrollingText__subheading-small {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0 5px 0;
}

.form__scrollingText__list__item {
    line-height: 1.5;
    list-style-position: inside;
    list-style-type: disc;
}

.form__heading-authorisation {
    margin-bottom: 20px;
}

.form__checkboxDiv {
    margin-top: 20px;
}

.form__checkbox-terms:hover {
    cursor: pointer;
}

.form__label-terms {
    padding-left: 5px;
    line-height: 1.8;
}

.form__link {
    display: inline-block;
    float: right;
    margin-top: 20px;
}

.form__button-edit {
    display: inline-block;
}

.form__button-delete {
    display: inline-block;
    color: red;
}

.form__button:hover {
    opacity: 0.7;
}

.addPlacementForm, 
.editPlacementForm {
    display: none;
    max-width: 800px;
}

.addPlacementForm.active, 
.editPlacementForm.active {
    display: block;
}

.form__boxContainer {
    margin-bottom: 30px;
}

.signatureTitleDiv {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
}

.button__clear {
    background-color: #0C3580;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-size: 16px;
    height: min-content;
    line-height: 1;
    padding: 10px;
    width: min-content;
}

.button__clear:hover {
    opacity: 0.7;
}

.m-signature-pad--body {
    max-width: 1000px;
    position: relative;
}

.m-signature-pad--body canvas {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 250px;
    border: 1px solid #E6E9EF;
}

.penIcon {
    display: inline-block;
    height: 25px;
    left: 10px;
    position: absolute;
    top: 7px;
    width: 25px;
    z-index: 9;
}

.form__spinner {
    display: none;
    height: 80px;
    margin: 20px 0;
    width: 100px;
}

/* Layout */

.card {
	background-color: #fff;
    border-radius: 4px;
    margin-bottom: 15px;
	max-width: 800px;
	padding: 15px;
	position: relative;
    width: 100%;
}

.table {
	margin-bottom: 60px;
    width: 100%;
}

.tr {
	align-items: center;
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: grid;
}

.tr:last-child {
    border-bottom: 1px solid #E1E1E1;
}

.th {
    line-height: 1.3;
}

.td {
    font-weight: 400;
}

.clickable {
	cursor: pointer;
	transition: all 0.3s;
}

.clickable:hover {
	opacity: 0.7
}

/* Media queries */

@media (max-width: 1340px) {
    
    .tableContainer {
        padding: 40px 10px;
    }
    
    .form__table-magazine .form__table__row {
        grid-template-columns: 20% 10% repeat(7, 10%);
    }
    
}

@media (max-width: 1100px) {

    .title {
        text-align: center;
    }

    .form__customerDetails {
        display: block;
    }

    .form__section {
        display: block;
        width: 100%;
    }

    .form__section-left {
        border-right: none;
        padding-right: 0;
    }

    .form__section-right {
        padding-left: 0;
    }

    .form__table__row__td, .form__table__row__th {
        padding: 15px;
    }

    .form__table__row__th-description {
        grid-area: description;
    }
    .form__table__row__th-rate {
        grid-area: rate;
    }
    .form__table__row__td-issue {
        display: block;
        text-align: right;
    }
    .issue1 {
        grid-area: issue1;
    }
    .issue2 {
        grid-area: issue2;
    }
    .issue3 {
        grid-area: issue3;
    }
    .issue4 {
        grid-area: issue4;
    }
    .issue5 {
        grid-area: issue5;
    }
    .issue6 {
        grid-area: issue6;
    }
    .checkbox1 {
        grid-area: checkbox1;
    }
    .checkbox2 {
        grid-area: checkbox2;
    }
    .checkbox3 {
        grid-area: checkbox3;
    }
    .checkbox4 {
        grid-area: checkbox4;
    }
    .checkbox5 {
        grid-area: checkbox5;
    }
    .checkbox6 {
        grid-area: checkbox6;
    }

    .form__table-magazine .form__table__row {
        grid-template-areas: 'description rate'
                            'issue1 checkbox1'
                            'issue2 checkbox2'
                            'issue3 checkbox3'
                            'issue4 checkbox4'
                            'issue5 checkbox5'
                            'issue6 checkbox6';
        grid-template-columns: 60% 40%;
    }

    .form__table__row__th-issue {
        display: none;
    }
}

@media only screen and (max-width: 900px) {

    .form__grid-2, .form__flexContainer {
        display: block;
    }
    
}

@media (max-width: 740px) {

    .totalDiv__item {
        display: block;
        margin-bottom: 5px;
    }
}

@media (max-width: 700px) {

    .form__tabs__tab {
        padding: 20px 10px 22px 10px;
    }
    
    .form__table__row__td-desktopDateLabel,
    .form__table__row__td-desktopQuantityLabel {
        display: none;
    }
    
    .form__table__row__th-desktopDescription {
        grid-area: description;
    }

    .form__table__row__th-desktopRate {
        grid-area: rate;
    }

    .form__table__row__th-desktop {
        display: none;
        grid-area: label;
    }

    .form__table__row__td-mobileDateLabel {
        display: block;
        grid-area: dateLabel;
        text-align: right;
    }
    .form__table__row__td-dateInput {
        display: block;
        grid-area: dateInput;
    }
    .form__table__row__td-mobileQuantityLabel {
        display: block;
        grid-area: quantityLabel;
        text-align: right;
    }
    .form__table__row__td-quantityInput {
        display: block;
        border-top: 1px solid #E6E9EF;
        border-bottom: 1px solid #E6E9EF;
        grid-area: quantityInput;
    }

    .form__table__row__td-mobileRate {
        grid-area: rate;
    }

    .form__table-website .form__table__row, 
    .form__table-edm .form__table__row, 
    .form__table-onlineArticles .form__table__row {
        grid-template-areas: 'description rate'
                            'dateLabel dateInput'
                            'quantityLabel quantityInput';
        grid-template-columns: 60% 40%;
    }

    .infoIcon {
        padding: 1px 9px 1px 0;
        margin-left: 10px;
    }

    .infoBox {
        border: 1px solid #E6E9EF;
        right: -30vw;
        top: 25px;
        width: 60vw;
    }

}

@media (max-width: 570px) {

    h2 {
        text-align: center;
    }

    .form__tabs__tab {
        font-size: 16px;
    }
    
}

