/* CalendarList CSS */

.CalendarList {
    background-color: #ffffff;
    border: 1px solid #E6E9EF;
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 15px));
    gap: 20px;
    margin-bottom: 40px;
    padding: 20px;
}


@media only screen and (max-width: 1420px) {
    
    .CalendarList {
        grid-template-columns: repeat(3, calc(33.333% - 13.3333px));
    }
}

@media only screen and (max-width: 1050px) {
    
    .CalendarList {
        grid-template-columns: repeat(2, calc(50% - 10px));
    }
}

@media only screen and (max-width: 650px) {
    
    .CalendarList {
        display: block;
    }
}
