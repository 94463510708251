/* PlacementListItem CSS */

.PlacementListItem {
    align-items: center;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 50px auto 150px 20px 20px;
}

@media  (max-width: 600px) {
    
    .PlacementListItem {
        display: grid;
        grid-template-columns: auto 120px 20px 20px;
    }

    .PlacementListItem__index {
        display: none;
    }
}

@media  (max-width: 450px) {
    
    .PlacementListItem {
        display: grid;
        grid-template-columns: auto 90px 20px 20px;
    }
    
    .PlacementListItem__rate {
        text-align: center;
    }
}
