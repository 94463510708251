/* UserListItem CSS */

.UserListItem {
    align-items: center;
    column-gap: 15px;
    display: grid;
    grid-template-areas: 'name email edit delete';
    grid-template-columns: auto 350px 30px 30px;
}

.UserListItem__name {
    grid-area: name;
}

.UserListItem__email {
    grid-area: email;
}

.UserListItem__edit {
    grid-area: edit;
}

.UserListItem__delete {
    grid-area: delete;
}

@media only screen and (max-width: 750px) {

    .UserListItem {
        column-gap: 0;
        grid-template-columns: 50% 50%;
        grid-template-areas: 'name name' 'email email' 'edit delete';
        row-gap: 10px;
    }

    .UserListItem__name, 
    .UserListItem__email {
        text-align: center;
    }

    .UserListItem__delete {
        margin: 0 0 0 auto;
        width: max-content;
    }
}
/* 
@media only screen and (max-width: 570px) {

    .UserListItem {
        grid-template-columns: auto 220px 50px 30px;
    }

}

@media only screen and (max-width: 500px) {

    .UserListItem {
        grid-template-columns: auto 130px 50px 30px;
    }

} */
