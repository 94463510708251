/* BookingsList CSS */

.BookingList__search {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.BookingList__search__form {
    align-items: center;
    display: flex;
    max-width: 800px;
    width: 100%;
}

.BookingList__search__form__text {
    display: inline;
    width: max-content;
    width: 100%;
}

.BookingList__search__form__input {
    border: 1px solid #E6E9EF;
    display: block;
    outline: 0;
    padding: 10px;
    max-width: 400px;
    width: 100%;
}

@media only screen and (max-width: 600px) {

    .BookingList__search {
        display: block;
    }

    .BookingList__search__form {
        margin-bottom: 20px;
    }
    
    .BookingList__search__button {
        margin: 0 0 20px auto;
    }
}