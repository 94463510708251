.BTDSnackbar {
    align-items: center;
    animation: slide-up 0.3s ease-in-out;
    background-color: #518f05;
    border-radius: 50px;
    bottom: 25px;
    color: white;
    display: flex;
    height: max-content;
    justify-content: space-between;
    left: 25px;
    position: fixed;
    padding: 15px 30px;
    z-index: 9999;
}

.BTDSnackbar__icon {
    background-size: contain;
    height: 25px;
    margin-right: 8px;
    width: 25px; 
}

.BTDSnackbar__icon-tick {
    background-image: url('./tick.svg');
}

.BTDSnackbar__icon-exclamation {
    background-image: url('./exclam.svg');
}

.BTDSnackbar.error, .BTDSnackbar.info {
    animation: slide-down 0.3s ease-in-out;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
}

.BTDSnackbar.error {
    background-color: #e02323;
}

.BTDSnackbar.info {
    background-color: #1976d2;
}

@keyframes slide-up {
    from {
        transform: translateX(0%) translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%) translateY(0);
        opacity: 1;
    }
}

@keyframes slide-down {
    from {
        transform: translateX(-50%) translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
}