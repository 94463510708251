.Container {
	flex: 1;
	display: inherit;
	flex-direction: inherit;
	align-items: inherit;
	justify-content: inherit;
	margin-left: 60px;
	margin-right: 60px;
	position: relative;
}

@media only screen and (max-width: 1150px) {

	.Container {
		margin-left: 20px;
		margin-right: 20px;
	}
}

@media only screen and (max-width: 410px) {

	.Container {
		margin-left: 10px;
		margin-right: 10px;
	}
}