/* PublicLayout CSS */

.PublicLayout {
}

.PublicLayout__header {
    background-color: #0C3580;
    height: 100px;
}

.PublicLayout__header__container {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
    max-width: 1700px;
    width: 100%;
}

.PublicLayout__header__logo {
    height: auto;
    width: 80px;
}

.PublicLayout__header__logo img {
    height: auto;
    width: 100%;
}

.PublicLayout__header__title {
    color: white;
    float: right;
    font-size: 25px;
    margin: 0 0 0 auto;
}

.PublicLayout__container {
    margin: 80px auto;
    max-width: 1700px;
    width: 100%;
}

@media (max-width: 400px) {

    .PublicLayout__header__title {
        padding: 30px 10px;
    }
}