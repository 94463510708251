/* BookingViewScreen CSS */

.BookingViewScreen__buttons {
    column-gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    margin: 0 0 20px auto;
    max-width: max-content;
    width: 100%;
}

.BookingViewScreen__status {
    align-items: center;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px 30px;
}

.BookingViewScreen__status.grey {
    background-color: #D9D9D9;
}

.BookingViewScreen__status.green {
    background-color: #6DA7265E;
}

.BookingViewScreen__status.orange {
    background-color: #FF51004F;
}

.BookingViewScreen__status__text {
    margin-bottom: 0;
}

.grey .BookingViewScreen__status__text {
    color: #555;
}

.green .BookingViewScreen__status__text {
    color: #6DA726;
}

.orange .BookingViewScreen__status__text {
    color: #FE5100;
}

.gridTable {
    background-color: #ffffff;
    border: 1px solid #E6E9EF;
    display: grid;
    grid-template-columns: 250px auto;
    margin-bottom: 60px;
}

.gridTable__heading {
    border-right: 1px solid #E6E9EF;
    padding: 10px 20px;
}

.gridTable__data {
    padding: 10px 20px;
}

.gridTable__heading-top, 
.gridTable__data-top {
    padding-top: 20px;
}

.gridTable__data__adDiv {
    display: grid;
    grid-template-columns: calc(28% - 16px) repeat(4, calc(18% - 16px));
    column-gap: 20px;
    padding-bottom: 10px;
}

.gridTable__data__adDiv__item {
    padding-right: 20px;
}

.gridTable__data-signature {
    max-width: 350px;
}
