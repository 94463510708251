/* BTDMultiselect */

.BTDMultiselect {
    font-size: inherit;
    margin-bottom: 30px;
    position: relative;
}

.BTDMultiselect.medium {
    width: calc(50% - 10px);
}

.BTDMultiselect.small {
    width: calc(25% - 20px);
}

.BTDMultiselect__description {
}

.BTDMultiselect__select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.3;
    min-height: 63px;
    outline: none;
    padding: 20px 30px 20px 20px;
    width: 100%;
}

.BTDMultiselect__select.active {
    padding: 12px 30px 12px 20px;
}

.BTDMultiselect__select__tab {
    align-items: center;
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin: 5px;
    padding: 5px;
    width: max-content;
}

.BTDMultiselect__select__downArrow {
    background-image: url('./down-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 15px;
    top: calc(40%);
    /* top: 27px; */
    transition: all 0.3s;
    width: 15px;
    z-index: -1;
}

.BTDMultiselect__select__downArrow.open {
    transform: rotate(180deg);
}

.BTDMultiselect__label {
    background-color: #fff;
    bottom: 0;
    color: rgba(0,0,0,0.5);
    font-size: inherit;
    height: 100%;
    position: absolute;
    left: 20px;
    top: 0;
    transition: all 0.3s;
    z-index: -1;
}

.BTDMultiselect__label__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    transition: all 0.3s;
}

.BTDMultiselect__label.active {
    bottom: auto;
    font-size: 11px;
    height: auto;
    left: 15px;
    padding: 0 5px;
    top: -5px;
    z-index: 9;
}

.BTDMultiselect__downArrow:-webkit-any(:checked), 
.BTDMultiselect__downArrow:checked {
    transform: rotate(180deg);
}

.BTDMultiselect__dropdown {
    background-color: #fff;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    max-height: 150px;
    overflow: scroll;
    position: absolute;
    width: 100%;
    z-index: 9;
}

.BTDMultiselect__dropdown__option {
    padding: 10px 20px;
}

.BTDMultiselect__dropdown__option:hover {
    background-color: #efefef;
    cursor: pointer;
}

.BTDMultiselect__error {
    color: red;
    transform: translateY(-20px);
}

