/* CouponListItem CSS */

.CouponListItem {
    align-items: center;
    display: grid;
    grid-template-areas: 'switch code placements rate delete';
    grid-template-columns: 60px auto 260px 70px 30px;
}

.CouponListItem__switch {
    grid-area: switch;
}

.CouponListItem__code {
    grid-area: code;
}

.CouponListItem__placements {
    grid-area: placements;
}

.CouponListItem__rate {
    grid-area: rate;
}

.CouponListItem__delete {
    grid-area: delete;
}

.CouponListItem__dropdown {
    background-color: #fff;
    border: 1px solid #E6E9EF;
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    top: 100%;
    z-index: 9;
}

@media only screen and (max-width: 700px) {

    .CouponListItem {
        grid-template-columns: 60px auto 220px 70px 30px;
    }

    .CouponListItem__index {
        display: none;
    }
}

@media only screen and (max-width: 540px) {

    .CouponListItem {
        grid-template-areas: 'code code' 'placements placements' 'rate rate' 'switch delete';
        grid-template-columns: 50% 50%;
        row-gap: 15px;
        text-align: center;
    }

    .CouponListItem__placements {
        margin: 0 auto;
        width: max-content;
    }
}
