/* BTDSwitch */

.BTDSwitch {
    display: flex;
    height: 28px;
    margin-bottom: 30px;
    position: relative;
}

.BTDSwitch__wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 15px;
    cursor: pointer;
    width: 50px;
}

.BTDSwitch__input {
    display: none;
}

.BTDSwitch__slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    transform: translateX(0);
    transition: transform 0.2s ease;
}

.BTDSwitch__wrapper.checked {
    background-color: #0C3580;
}

.BTDSwitch__input:checked + .BTDSwitch__slider {
    transform: translateX(23px);
}

.BTDSwitch__label {
    display: inline-block;
    margin-left: 65px;
    /* transform: translateY(-38.5px); */

}