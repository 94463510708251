/* AdminLayout CSS */

.AdminLayout {
    border-right: 1px solid #E6E9EF;
    height: 100%;
    min-height: 100vh;
    max-width: 1900px;
    margin: 0 auto;
}

.AdminLayout__wrapper{
    margin-left: 200px;
}

.AdminLayout__header {
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #E6E9EF;
    display: flex;
    height: 70px;
    justify-content: space-between;
}

.AdminLayout__header__linkDiv {
}

.AdminLayout__header__linkDiv__link {
    color: #172E5B;
}

.AdminLayout__header__linkDiv__link::after {
    content: " >";
    transition: margin-left 0.4s;
}

.AdminLayout__header__linkDiv:hover .AdminLayout__header__linkDiv__link::after {
    margin-left: 8px;
}

.AdminLayout__header__user {
    align-items: center;
    display: flex;
}

.AdminLayout__header__user__name {
    color: #939393;
    margin-right: 20px;
}

.AdminLayout__sidebar {
    background-color: #0C3580;
    display: block;
    height: 100%;
    min-height: 100vh;
    position: fixed;
    width: 200px;
}

.AdminLayout__sidebar__logo {
    height: 100px;
    margin: 20px auto 100px auto;
    width: 100px;
}

.AdminLayout__sidebar__logo img {
    height: auto;
    width: 100%;
}

.AdminLayout__sidebar__nav {
    text-align: center;
}

.AdminLayout__sidebar__nav__list__item {
    color: #ffffff;
    display: block;
    margin-bottom: 20px;
}

.AdminLayout__sidebar__nav__list__item a {
    color: #ffffff;
}

.AdminLayout__menuTab {
    background-color: #0C3580;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: none;
    left: -16px;
    padding: 12px;
    position: fixed;
    top: 95px;
    transform: rotate(90deg);
    z-index: 999999;
}

.AdminLayout__body {
    padding: 50px 0;
}

@media only screen and (max-width: 900px) {

    .AdminLayout__wrapper{
        margin-left: 140px;
    }

    .AdminLayout__sidebar {
        width: 140px;
    }
}

@media only screen and (max-width: 840px) {

    .AdminLayout__wrapper{
        margin-left: 0;
    }

    .AdminLayout__sidebar {
        bottom: 0;
        display: block;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 999999;
    }

    .AdminLayout__menuTab {
        display: block;
    }
}