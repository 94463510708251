/* BookingsList CSS */

.BookingsList {
    border-bottom: 1px solid #E1E1E1;
}

.BookingsList .tr-header {
    display: grid;
    grid-template-columns: 15% 25% 30% 15% 15%;
    padding: 0 15px 10px 15px;
}

@media only screen and (max-width: 700px) {

    .BookingsList .tr-header {
        display: none;
    }
}
