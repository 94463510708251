/* BTDCheckboxField */

.AnzaRegular.BTDCheckboxField {
    display: inline-block;
    max-width: max-content;
    width: 100%;
}

/* Customize the label (the container) */
.BTDCheckboxField__label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    /* margin-bottom: 30px; */
    /* padding-left: 35px; */
    /* padding-top: 5px; */
    position: relative;
    user-select: none;
}

.AnzaRegular .BTDCheckboxField__label {
    flex-direction: row-reverse;
    justify-content: left;
    max-width: max-content;
    width: 100%;
}

/* Hide the browser's default checkbox */
.BTDCheckboxField__input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
}

/* Create a custom checkbox */
.BTDCheckboxField__checkmark {
    background-color: #eee;
    border: 2px solid #ddd;
    border-radius: 3px;
    display: inline-block;
    height: 25px;
    margin: 0 auto;
    /* left: 0; */
    /* position: absolute; */
    position: relative;
    /* top: 0; */
    width: 25px;
}

.AnzaRegular .BTDCheckboxField__checkmark {
    margin: 0 10px 0 0;
}

/* On mouse-over, add a grey background color */
.BTDCheckboxField__label:hover input ~ .BTDCheckboxField__checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.BTDCheckboxField__label input:checked ~ .BTDCheckboxField__checkmark {
    background-color: #0C3580;
}

/* Create the checkmark/indicator (hidden when not checked) */
.BTDCheckboxField__checkmark:after {
    content: "";
    display: none;
    position: absolute;
}

/* Show the checkmark when checked */
.BTDCheckboxField__label input:checked ~ .BTDCheckboxField__checkmark:after {
    display: block;
    font-family: inherit;
}

/* Style the checkmark/indicator */
.BTDCheckboxField__label .BTDCheckboxField__checkmark:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    border: solid white;
    border-width: 0 3px 3px 0;
    left: 7px;
    height: 10px;
    transform: rotate(45deg);
    top: 2px;
    width: 5px;
} 