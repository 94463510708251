/* BookingCreateScreen CSS */

.BookingCreateScreen__search {
    margin-bottom:40px;
}

.BookingCreateScreen__search__form {
    margin-bottom: 10px;
}

.BookingCreateScreen__search__form__input {
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    outline: 0;
    padding: 10px;
    max-width: 400px;
    width: 100%;
}

.BookingCreateScreen__search__list {
    border: 1px solid #E1E1E1;
    background-color: #fff;
    border-radius: 4px;
    max-height: 200px;
    max-width: 569px;
    overflow: scroll;
    padding: 10px 15px;
}

.BookingCreateScreen__search__list__item {
    cursor: pointer;
}

.BookingCreateScreen__search__list__item:hover {
    background-color: #f8f9fd;
}